import ApplicationProfile from "../../../components/companyPostedJob/applicationProfile/ApplicationProfile"


function ApplicationProfilePage(){
    return (
        <>
        <ApplicationProfile/>
        </>
    )
}
export default ApplicationProfilePage
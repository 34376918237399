
function AboutMe() {
    return (
        <>
            <div className="job-header">
                <div className="contentbox">
                    <h3>About me</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla at enim quis tincidunt. Nulla condimentum dapibus efficitur. In massa felis, fringilla at urna vestibulum, mattis malesuada metus. Integer id lorem tortor. Pellentesque hendrerit sapien sit amet finibus pretium. Fusce eu sagittis orci. Quisque urna velit, facilisis interdum nisl nec, commodo tristique leo. Maecenas turpis augue, vulputate ac lorem in, euismod euismod tortor. Phasellus vitae lacinia est, ut porta leo. Morbi sit amet quam in risus gravida mattis. Suspendisse sodales massa et odio mollis, id ultricies ipsum semper. Duis pretium vestibulum dui at scelerisque.</p>
                </div>
                <div className="ptsklbx">
                    <h3>Skills</h3>
                    <div id="skill_div"><ul className="profileskills row"><li className="col-md-4" id="skill_14">
                        <div className="skillbox">Adobe Illustrator
                            <span className="text text-success">8 Year</span></div></li><li className="col-md-4" id="skill_15">
                            <div className="skillbox">Adobe Photoshop
                                <span className="text text-success">8 Year</span></div></li><li className="col-md-4" id="skill_16">
                            <div className="skillbox">Communication Skills
                                <span className="text text-success">5 Year</span></div></li><li className="col-md-4" id="skill_17">
                            <div className="skillbox">CSS
                                <span className="text text-success">8 Year</span></div></li><li className="col-md-4" id="skill_18">
                            <div className="skillbox">HTML
                                <span className="text text-success">8 Year</span></div></li><li className="col-md-4" id="skill_19">
                            <div className="skillbox">JavaScript
                                <span className="text text-success">5 Year</span></div></li><li className="col-md-4" id="skill_20">
                            <div className="skillbox">Jquery
                                <span className="text text-success">4 Year</span></div></li><li className="col-md-4" id="skill_21">
                            <div className="skillbox">WordPress
                                <span className="text text-success">3 Year</span></div></li></ul></div>
                </div>
            </div>
        </>
    )
}
export default AboutMe
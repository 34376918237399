
function Portfolio() {
    return (
        <>
            <div className="job-header">
                <div className="contentbox">
                    <h3>Portfolio</h3>
                    <div className id="projects_div"><ul className="row userPortfolio"><li className="col-md-3">
                        <div className="imgbox"><img src="https://hsalghanimdelivery.com/project_images/thumb/my-test-1-xczub-967.jpg" alt title />
                            <div className="itemHover">
                                <div className="zoombox"><a href="http://www.google.com" title="My test 1" className="item-zoom fancybox-effects-a"><i className="fa fa-search" aria-hidden="true" /></a> </div>
                                <div className="infoItem">
                                    <div className="itemtitle">
                                        <h5>My test 1</h5>
                                        <p>15 Aug, 2021 - 15 Aug, 2021<br />
                                            Lorem ipsum dolor sit ame...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li><li className="col-md-3">
                            <div className="imgbox"><img src="https://hsalghanimdelivery.com/admin_assets/no-image.png" alt title />
                                <div className="itemHover">
                                    <div className="zoombox"><a href="http://www.yourdomain.com" title="Test Project 2" className="item-zoom fancybox-effects-a"><i className="fa fa-search" aria-hidden="true" /></a> </div>
                                    <div className="infoItem">
                                        <div className="itemtitle">
                                            <h5>Test Project 2</h5>
                                            <p>15 Aug, 2021 - 15 Aug, 2021<br />
                                                Lorem ipsum dolor sit ame...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li><li className="col-md-3">
                            <div className="imgbox"><img src="https://hsalghanimdelivery.com/project_images/thumb/test-project-3-o2whp-818.jpg" alt title />
                                <div className="itemHover">
                                    <div className="zoombox"><a href="http://www.yourdomain.com" title="Test Project 3" className="item-zoom fancybox-effects-a"><i className="fa fa-search" aria-hidden="true" /></a> </div>
                                    <div className="infoItem">
                                        <div className="itemtitle">
                                            <h5>Test Project 3</h5>
                                            <p>15 Aug, 2021 - 15 Aug, 2021<br />
                                                Lorem ipsum dolor sit ame...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li></ul></div>
                </div>
            </div>
        </>
    )
}
export default Portfolio

function Experience() {
    return (
        <>
            <div className="job-header">
                <div className="contentbox">
                    <h3>Experience</h3>
                    <div className id="experience_div"><ul className="experienceList"><li>
                        <div className="row">
                            <div className="col-md-2"><img src="https://hsalghanimdelivery.com/images/work-experience.png" alt="work experience" /></div>
                            <div className="col-md-10">
                                <h4>My Test Company 1 | Alexander City</h4>
                                <div className="row">
                                    <div className="col-md-6">Web Designer</div>
                                    <div className="col-md-6">From 04 Jan, 2016 - 29 Dec, 2017</div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla at enim quis tincidunt. Nulla condimentum dapibus efficitur. In massa felis, fringilla at urna vestibulum, mattis malesuada metus.</p>
                            </div>
                        </div>
                    </li><li>
                            <div className="row">
                                <div className="col-md-2"><img src="https://hsalghanimdelivery.com/images/work-experience.png" alt="work experience" /></div>
                                <div className="col-md-10">
                                    <h4>My Test Company 2 | Kennesaw</h4>
                                    <div className="row">
                                        <div className="col-md-6">Senior Frontend Developer</div>
                                        <div className="col-md-6">From 08 Jan, 2018 - Currently working</div>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla at enim quis tincidunt. Nulla condimentum dapibus efficitur. In massa felis, fringilla at urna vestibulum, mattis malesuada metus. Integer id lorem tortor. Pellentesque hendrerit sapien sit amet finibus pretium.</p>
                                </div>
                            </div>
                        </li></ul></div>
                </div>
            </div>
        </>
    )
}
export default Experience
import { Col, Nav, Row, Tab } from "react-bootstrap"

function CompanyMessage() {
    return (
        <>
            <h3>Company Messages</h3>
            <div className="d-flex align-items-start " >
                <div className="nav flex-column nav-pills me-3 define-width" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">

                        <div className="image">
                            <img src="https://hsalghanimdelivery.com/admin_assets/no-image.png" style={{ maxHeight: 100 }} alt="Daniyal Khan" title="Daniyal Khan" />
                        </div>
                        <div className="user-name">
                            <div className="author"> <span>Daniyal Khan</span>
                            </div>
                            <div className="count-messages">
                                0
                            </div>
                        </div>

                    </button>
                    <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button>
                </div>
                <div className="tab-content tab-content-2" id="v-pills-tabContent">
                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div className="message-details">
                            <h4> </h4>
                            <div id="append_messages"><ul className="messages message6"><li className="my-message clearfix tab6">
                                <figure className="profile-picture"><img src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png" alt="Abaris Softech Pvt Ltd" title="Abaris Softech Pvt Ltd" /> </figure>
                                <div className="message"> this is test
                                    <div className="time"><i className="fa fa-clock-o" />2 years ago</div>
                                </div>
                            </li>
                                <li className="my-message clearfix tab6">
                                    <figure className="profile-picture"><img src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png" alt="Abaris Softech Pvt Ltd" title="Abaris Softech Pvt Ltd" /> </figure>
                                    <div className="message"> hi how are you
                                        <div className="time"><i className="fa fa-clock-o" />2 years ago</div>
                                    </div>
                                </li>
                                <li className="my-message clearfix tab6">
                                    <figure className="profile-picture"><img src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png" alt="Abaris Softech Pvt Ltd" title="Abaris Softech Pvt Ltd" /> </figure>
                                    <div className="message"> ewr
                                        <div className="time"><i className="fa fa-clock-o" />1 hour ago</div>
                                    </div>
                                </li>
                            </ul>
                                <div className="chat-form" type="post">
                                    <form className="form-inline" noValidate="novalidate">
                                        <input type="hidden" name="_token" defaultValue="yOLkmzkNHvEqrpQfgPhK2oTsG5cZDkDC3NFxWXgl" />  <div className="form-group">
                                            <div className="input-wrap">
                                                <input type="hidden" name="seeker_id" defaultValue={6} />
                                                <textarea className="form-control" name="message" placeholder="Type Your Message here.." defaultValue={""} />
                                                <div className="input-group-prepend"> <button type="submit" className="input-group-text" id="inputGroupPrepend3"><i className="fas fa-paper-plane" aria-hidden="true" /></button> </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">...rfre</div>
                   
                </div>
            </div>

            {/* <div className="myads message-body">
                <div className="row">
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Tab 1</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Tab 2</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    
                                    efrf
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    
                                    erffre
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                    <div className="col-lg-4 col-md-4">
                        <div className="message-inbox">
                            <div className="message-header">
                            </div>
                            <div className="list-wrap">
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item className="message-history">
                                        <Nav.Link eventKey="first" className="message-grid">
                                            <div className="image"><img src="https://hsalghanimdelivery.com/admin_assets/no-image.png" alt="Daniyal Khan" title="Daniyal Khan" style={{ maxHeight: 100 }} /></div>
                                            <div className="user-name"><div className="author"> <span>Daniyal Khan</span></div><div className="count-messages">0</div></div>


                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Tab 2</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 ">
                        <div className="message-details">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    vefsve
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    df
                                </Tab.Pane>
                            </Tab.Content>
                          
                        </div>
                    </div>
                </div>
            </div> */}

        </>
    )
}
export default CompanyMessage
import { Link } from "react-router-dom";

function JobHeaderLeft() {
  return (
    <>
      <div className="col-md-8">
        <div className="job-header">
          <div className="contentbox">
            <h3>About Company</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              fermentum condimentum mauris, non posuere urna consectetur quis.
              Suspendisse semper eu eros eget convallis. Etiam mattis blandit
              nulla, non venenatis risus varius vel. Morbi fringilla dignissim
              elit id blandit. Pellentesque vel luctus felis. Vestibulum eros
              nibh, consequat ut felis in, vehicula lobortis quam. Duis diam
              mauris, convallis in risus in, gravida hendrerit lacus. Donec
              euismod accumsan sem et aliquam. Duis a velit eget urna mattis
              ultricies. Aliquam nibh ipsum, aliquet nec sollicitudin non,
              fermentum nec diam. Vestibulum ac urna vehicula, dapibus dui quis,
              aliquet neque. Sed ac tristique purus. Vestibulum tempor, erat eu
              porta tempor, erat ipsum cursus dui, eu tempus mauris leo id mi.
              Sed ultrices sollicitudin vehicula. Proin in ullamcorper massa.
            </p>
          </div>
        </div>

        <div className="relatedJobs">
          <h3>Job Openings</h3>
          <ul className="searchList">
            <li>
              <div className="row">
                <div className="col-lg-9 col-md-8">
                  <div className="jobimg">
                    <a
                      href="https://hsalghanimdelivery.com/job/ios-developer-2"
                      title="IOS Developer"
                    >
                      {" "}
                      <img
                        src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                        alt="Abaris Softech Pvt Ltd"
                        title="Abaris Softech Pvt Ltd"
                      />{" "}
                    </a>
                  </div>
                  <div className="jobinfo">
                    <h3>
                      <a
                        href="https://hsalghanimdelivery.com/job/ios-developer-2"
                        title="IOS Developer"
                      >
                        IOS Developer
                      </a>
                    </h3>
                    <div className="companyName">
                      <a
                        href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                        title="Abaris Softech Pvt Ltd"
                      >
                        Abaris Softech Pvt Ltd
                      </a>
                    </div>
                    <div className="location">
                      <label className="fulltime me-2" title="Full Time/Permanent">
                        Full Time/Permanent
                      </label>
                      <label className="partTime" title="First Shift (Day)">
                        First Shift (Day)
                      </label>
                      - <span>Auburn</span>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="listbtn">
                    <Link to="/ios-developer-2 ">
                      View Detail
                    </Link>
                  </div>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                fermentum condimentum mauris, non posuere urna consectetur quis.
                Suspendisse semper eu...
              </p>
            </li>

            <li>
              <div className="row">
                <div className="col-lg-9 col-md-8">
                  <div className="jobimg">
                    <a
                      href="https://hsalghanimdelivery.com/job/project-manager-25"
                      title="Project Manager"
                    >
                      {" "}
                      <img
                        src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                        alt="Abaris Softech Pvt Ltd"
                        title="Abaris Softech Pvt Ltd"
                      />{" "}
                    </a>
                  </div>
                  <div className="jobinfo">
                    <h3>
                      <a
                        href="https://hsalghanimdelivery.com/job/project-manager-25"
                        title="Project Manager"
                      >
                        Project Manager
                      </a>
                    </h3>
                    <div className="companyName">
                      <a
                        href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                        title="Abaris Softech Pvt Ltd"
                      >
                        Abaris Softech Pvt Ltd
                      </a>
                    </div>
                    <div className="location">
                      <label className="fulltime me-2" title="Full Time/Permanent">
                        Full Time/Permanent
                      </label>
                      <label className="partTime" title="First Shift (Day)">
                        First Shift (Day)
                      </label>
                      - <span>Kaneohe Station</span>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="listbtn">
                    <Link to="/ios-developer-2 ">
                      View Detail
                    </Link>
                  </div>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </li>

            <li>
              <div className="row">
                <div className="col-lg-9 col-md-8">
                  <div className="jobimg">
                    <a
                      href="https://hsalghanimdelivery.com/job/full-stack-designer-30"
                      title="Full Stack Designer"
                    >
                      {" "}
                      <img
                        src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                        alt="Abaris Softech Pvt Ltd"
                        title="Abaris Softech Pvt Ltd"
                      />{" "}
                    </a>
                  </div>
                  <div className="jobinfo">
                    <h3>
                      <a
                        href="https://hsalghanimdelivery.com/job/full-stack-designer-30"
                        title="Full Stack Designer"
                      >
                        Full Stack Designer
                      </a>
                    </h3>
                    <div className="companyName">
                      <a
                        href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                        title="Abaris Softech Pvt Ltd"
                      >
                        Abaris Softech Pvt Ltd
                      </a>
                    </div>
                    <div className="location">
                      <label className="fulltime me-2" title="Full Time/Permanent">
                        Full Time/Permanent
                      </label>
                      <label className="partTime" title="Third Shift (Night)">
                        Third Shift (Night)
                      </label>
                      - <span>al-Ahmadi</span>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="listbtn">
                    <Link to="/ios-developer-2 ">
                      View Detail
                    </Link>
                  </div>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </li>

            <li>
              <div className="row">
                <div className="col-lg-9 col-md-8">
                  <div className="jobimg">
                    <a
                      href="https://hsalghanimdelivery.com/job/testte-31"
                      title="Full Stack Developer Required"
                    >
                      {" "}
                      <img
                        src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                        alt="Abaris Softech Pvt Ltd"
                        title="Abaris Softech Pvt Ltd"
                      />{" "}
                    </a>
                  </div>
                  <div className="jobinfo">
                    <h3>
                      <a
                        href="https://hsalghanimdelivery.com/job/testte-31"
                        title="Full Stack Developer Required"
                      >
                        Full Stack Developer Required
                      </a>
                    </h3>
                    <div className="companyName">
                      <a
                        href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                        title="Abaris Softech Pvt Ltd"
                      >
                        Abaris Softech Pvt Ltd
                      </a>
                    </div>
                    <div className="location">
                      <label className="fulltime me-2" title="Contract">
                        Contract
                      </label>
                      <label
                        className="partTime"
                        title="Second Shift (Afternoon)"
                      >
                        Second Shift (Afternoon)
                      </label>
                      - <span>Bessemer</span>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="listbtn">
                    <Link to="/ios-developer-2 ">
                      View Detail
                    </Link>
                  </div>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </li>

            <li>
              <div className="row">
                <div className="col-lg-9 col-md-8">
                  <div className="jobimg">
                    <a
                      href="https://hsalghanimdelivery.com/job/testte-32"
                      title="Graphic Designer Required"
                    >
                      {" "}
                      <img
                        src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                        alt="Abaris Softech Pvt Ltd"
                        title="Abaris Softech Pvt Ltd"
                      />{" "}
                    </a>
                  </div>
                  <div className="jobinfo">
                    <h3>
                      <a
                        href="https://hsalghanimdelivery.com/job/testte-32"
                        title="Graphic Designer Required"
                      >
                        Graphic Designer Required
                      </a>
                    </h3>
                    <div className="companyName">
                      <a
                        href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                        title="Abaris Softech Pvt Ltd"
                      >
                        Abaris Softech Pvt Ltd
                      </a>
                    </div>
                    <div className="location">
                      <label className="fulltime me-2" title="Contract">
                        Contract
                      </label>
                      <label
                        className="partTime"
                        title="Second Shift (Afternoon)"
                      >
                        Second Shift (Afternoon)
                      </label>
                      - <span>Bessemer</span>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="listbtn">
                    <Link to="/ios-developer-2 ">
                      View Detail
                    </Link>
                  </div>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </li>

            <li>
              <div className="row">
                <div className="col-lg-9 col-md-8">
                  <div className="jobimg">
                    <a
                      href="https://hsalghanimdelivery.com/job/seo-expert-33"
                      title="SEO Expert"
                    >
                      {" "}
                      <img
                        src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                        alt="Abaris Softech Pvt Ltd"
                        title="Abaris Softech Pvt Ltd"
                      />{" "}
                    </a>
                  </div>
                  <div className="jobinfo">
                    <h3>
                      <a
                        href="https://hsalghanimdelivery.com/job/seo-expert-33"
                        title="SEO Expert"
                      >
                        SEO Expert
                      </a>
                    </h3>
                    <div className="companyName">
                      <a
                        href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                        title="Abaris Softech Pvt Ltd"
                      >
                        Abaris Softech Pvt Ltd
                      </a>
                    </div>
                    <div className="location">
                      <label className="fulltime me-2" title="Full Time/Permanent">
                        Full Time/Permanent
                      </label>
                      <label
                        className="partTime"
                        title="Second Shift (Afternoon)"
                      >
                        Second Shift (Afternoon)
                      </label>
                      - <span>Casas Adobes</span>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="listbtn">
                    <a href="https://hsalghanimdelivery.com/job/seo-expert-33">
                      View Detail
                    </a>
                  </div>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                pretium nunc non justo placerat pulvinar. Vestibulum ac
                ullamcorper sapien, nec scelerisq...
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default JobHeaderLeft;

import { Outlet } from "react-router-dom"
import Aside from "../../components/common/aside/Aside"
import PageTitle from "../../components/common/pageTitle/PageTitle"


function CompanyPage() {
    return (
        <>
        {/* <PageTitle /> */}
        <div className="listpgWraper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                       <Aside/> 
                    </div>
                    <div className="col-md-9 col-sm-8">
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default CompanyPage
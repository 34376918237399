function JobHeaderRight() {
  return (
    <>
      <div className="col-md-4">
        <div className="job-header">
          <div className="jobdetail">
            <h3>Company Detail</h3>
            <ul className="jbdetail">
              <li className="row">
                <div className="col-md-6 col-xs-6">Is Email Verified</div>
                <div className="col-md-6 col-xs-6">
                  <span>No</span>
                </div>
              </li>
              <li className="row">
                <div className="col-md-6 col-xs-6">Total Employees</div>
                <div className="col-md-6 col-xs-6">
                  <span>301-600</span>
                </div>
              </li>
              <li className="row">
                <div className="col-md-6 col-xs-6">Established In</div>
                <div className="col-md-6 col-xs-6">
                  <span>2006</span>
                </div>
              </li>
              <li className="row">
                <div className="col-md-6 col-xs-6">Current jobs</div>
                <div className="col-md-6 col-xs-6">
                  <span>6</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="job-header">
          <div className="jobdetail">
            <iframe
              src="https://maps.google.it/maps?q=&output=embed"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default JobHeaderRight;

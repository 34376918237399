
function VideoProfile() {
    return (
        <>
            <div className="job-header">
                <div className="contentbox">
                    <h3>Video Profile</h3>
                    <iframe src="https://www.youtube.com/embed/538cRSPrwZU" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </div>
            </div>
        </>
    )
}
export default VideoProfile
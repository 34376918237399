
function PageTitle({pageTitle}) {

    return (
        <>
      <div className="pageTitle">
  <div className="container">
    <div className="row">
      <div className="col-md-6 col-sm-6">
        <h1 className="page-heading">Company Profile</h1>
      </div>
      <div className="col-md-6 col-sm-6">
        <div className="breadCrumb"><a href="#">Home</a> / <span>Company</span></div>
      </div>
    </div>
  </div>
</div>

        </>
    )
}
export default PageTitle
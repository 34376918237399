function ViewDetailRight() {
  return (
    <>
      <div className="col-lg-5">
        <div className="companyinfo">
          <h3>
            <i className="fas fa-building" aria-hidden="true" /> Company
            Overview
          </h3>
          <div className="companylogo">
            <a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9">
              <img
                src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                alt="Abaris Softech Pvt Ltd"
                title="Abaris Softech Pvt Ltd"
              />
            </a>
          </div>
          <div className="title">
            <a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9">
              Abaris Softech Pvt Ltd
            </a>
          </div>
          <div className="ptext">New Delhi, Delhi, India</div>
          <div className="opening">
            <a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9">
              6 Current Jobs Openings
            </a>
          </div>
          <div className="clearfix" />
          <hr />
          <div className="companyoverview">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              fermentum condimentum mauris, non posuere urna consectetur quis.
              Suspendisse semper eu eros eget convallis. Etiam mattis blandit
              nulla, non venenatis risus varius vel. Morbi fringilla dig...{" "}
              <a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9">
                Read More
              </a>
            </p>
          </div>
        </div>

        <div className="relatedJobs">
          <h3>Related Jobs</h3>
          <ul className="searchList">
            <li>
              <div className="jobinfo">
                <h3>
                  <a
                    href="https://hsalghanimdelivery.com/job/ios-developer-2"
                    title="IOS Developer"
                  >
                    IOS Developer
                  </a>
                </h3>
                <div className="companyName">
                  <a
                    href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                    title="Abaris Softech Pvt Ltd"
                  >
                    Abaris Softech Pvt Ltd
                  </a>
                </div>
                <div className="location">
                  <span>Auburn</span>
                </div>
                <div className="location">
                  <label className="fulltime">Full Time/Permanent</label>
                  <label className="partTime">First Shift (Day)</label>{" "}
                </div>
              </div>
              <div className="clearfix" />
            </li>
          </ul>
        </div>

        <div className="job-header">
          <div className="jobdetail">
            <h3>
              <i className="fas fa-map-marker" aria-hidden="true" /> Google Map
            </h3>
            <div className="gmap">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d66689.1610893612!2d77.20018446424909!3d28.542205941928287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38abca3560d%3A0xbd54bc439e45d16a!2sAbaris%20Softech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1670424928238!5m2!1sen!2sin"
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ViewDetailRight;

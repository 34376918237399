import { Link } from "react-router-dom";

function ViewDetailLeft() {
  return (
    <>
      <div className="col-lg-7">
        <div className="job-header">
          <div className="jobmainreq">
            <div className="jobdetail">
              <h3>
                <i className="fa fa-align-left" aria-hidden="true" /> Job Detail
              </h3>
              <ul className="jbdetail">
                <li className="row">
                  <div className="col-md-4 col-xs-5">Location:</div>
                  <div className="col-md-8 col-xs-7">
                    <span>Auburn, Alabama, United States of America</span>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4 col-xs-5">Company:</div>
                  <div className="col-md-8 col-xs-7">
                    <a href="https://hsalghanimdelivery.com/company/9">
                      Abaris Softech Pvt Ltd
                    </a>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4 col-xs-5">Type:</div>
                  <div className="col-md-8 col-xs-7">
                    <span className="permanent">Full Time/Permanent</span>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4 col-xs-5">Shift:</div>
                  <div className="col-md-8 col-xs-7">
                    <span className="freelance">First Shift (Day)</span>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4 col-xs-5">Career Level:</div>
                  <div className="col-md-8 col-xs-7">
                    <span>Experienced Professional</span>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4 col-xs-5">Positions:</div>
                  <div className="col-md-8 col-xs-7">
                    <span>4</span>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4 col-xs-5">Experience:</div>
                  <div className="col-md-8 col-xs-7">
                    <span>4 Year</span>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4 col-xs-5">Gender:</div>
                  <div className="col-md-8 col-xs-7">
                    <span>Male</span>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4 col-xs-5">Degree:</div>
                  <div className="col-md-8 col-xs-7">
                    <span>Bachelors</span>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4 col-xs-5">Apply Before:</div>
                  <div className="col-md-8 col-xs-7">
                    <span>Jun 29, 2023</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="jobButtons">
            <Link to="/email-to-friend" className="btn">
              <i className="fas fa-envelope" aria-hidden="true" /> Email to
              Friend
            </Link>
            <a
              href="https://hsalghanimdelivery.com/add-to-favourite-job/ios-developer-2"
              className="btn"
            >
              <i className="fas fa-floppy-o" aria-hidden="true" /> Add to
              Favourite
            </a>{" "}
            <Link to="/report-abuse" className="btn report">
              <i className="fas fa-exclamation-triangle" aria-hidden="true" />{" "}
              Report Abuse
            </Link>
          </div>
        </div>
        <div className="job-header">
          <div className="contentbox">
            <h3>
              <i className="fas fa-file-text" aria-hidden="true" /> Job
              Description
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              fermentum condimentum mauris, non posuere urna consectetur quis.
              Suspendisse semper eu eros eget convallis. Etiam mattis blandit
              nulla, non venenatis risus varius vel. Morbi fringilla dignissim
              elit id blandit. Pellentesque vel luctus felis. Vestibulum eros
              nibh, consequat ut felis in, vehicula lobortis quam. Duis diam
              mauris, convallis in risus in, gravida hendrerit lacus. Donec
              euismod accumsan sem et aliquam. Duis a velit eget urna mattis
              ultricies. Aliquam nibh ipsum, aliquet nec sollicitudin non,
              fermentum nec diam. Vestibulum ac urna vehicula, dapibus dui quis,
              aliquet neque. Sed ac tristique purus. Vestibulum tempor, erat eu
              porta tempor, erat ipsum cursus dui, eu tempus mauris leo id mi.
              Sed ultrices sollicitudin vehicula. Proin in ullamcorper massa.
              <br />
              <br />
            </p>
            <ul>
              <li>this 1</li>
              <li>this is 2</li>
              <li>this is 3</li>
            </ul>
            <p />
          </div>
        </div>
        <div className="job-header benefits">
          <div className="contentbox">
            <h3>
              <i className="fa fa-file-text" aria-hidden="true" /> Benefits
            </h3>
            <p />
            <ul>
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li>Fusce id diam vitae lacus consectetur placerat.</li>
              <li>Nulla consequat metus ac ante semper vehicula.</li>
              <li>Vivamus eleifend elit ut porta hendrerit.</li>
              <li>
                Donec suscipit magna eu sapien elementum, sit amet ornare ipsum
                consequat.
              </li>
              <li>Etiam faucibus nisl accumsan commodo fringilla.</li>
            </ul>
            <p />
          </div>
        </div>
        <div className="job-header">
          <div className="contentbox">
            <h3>
              <i className="fas fa-puzzle-piece" aria-hidden="true" /> Skills
              Required
            </h3>
            <ul className="skillslist">
              <li>
                <Link to="/job_skill_css">
                  CSS
                </Link>
              </li>
              <li>
                <Link to="/job_skill_html">
                  HTML
                </Link>
              </li>
              <li>
                <Link to="/job_skill-js">JavaScript</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default ViewDetailLeft;

import { Link } from "react-router-dom"

function DashBoard() {
    return (
        <>
            <ul className="row profilestat">
                <li className="col-md-4 col-6">
                    <div className="inbox"> <i className="fas fa-clock" aria-hidden="true" />
                        <h6><Link to="posted-jobs">6</Link></h6>
                        <strong>Open Jobs</strong> </div>
                </li>
                <li className="col-md-4 col-6">
                    <div className="inbox"> <i className="fas fa-user" aria-hidden="true" />
                        <h6><a href="https://hsalghanimdelivery.com/company-followers">0</a></h6>
                        <strong>Followers</strong> </div>
                </li>
                <li className="col-md-4 col-6">
                    <div className="inbox"> <i className="fas fa-envelope" aria-hidden="true" />
                        <h6><a href="https://hsalghanimdelivery.com/company-messages">0</a></h6>
                        <strong>Messages</strong> </div>
                </li>
            </ul>
            <div className="paypackages">
                {/*-four-paln*/}
                <div className="four-plan">
                    <h3>Our Packages</h3>
                    <div className="row">             <div className="col-md-4 col-sm-6 col-xs-12">
                        <ul className="boxes">
                            <li className="plan-name">Basic</li>
                            <li>
                                <div className="main-plan">
                                    <div className="plan-price1-1">KYD</div>
                                    <div className="plan-price1-2">9.99</div>
                                    <div className="clearfix" />
                                </div>
                            </li>
                            <li className="plan-pages">Can post jobs : 10</li>
                            <li className="plan-pages">Package Duration : 30 Days</li>
                            <li className="order paypal"><a href="#" data-toggle="modal" data-target="#buypack3" className="reqbtn">Buy Now</a></li>
                        </ul>
                        <div className="modal fade" id="buypack3" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="fas fa-times" />
                                        </button>
                                        <div className="invitereval">
                                            <h3>Please Choose Your Payment Method to Pay</h3>
                                            <div className="totalpay">Total Amount to pay: <strong>KYD9.99</strong></div>
                                            <ul className="btn2s">
                                                <li className="order paypal"><a href="https://hsalghanimdelivery.com/order-package/3"><i className="fab fa-cc-paypal" aria-hidden="true" /> pay with paypal</a></li>
                                                <li className="order"><a href="https://hsalghanimdelivery.com/stripe-order-form/3/new"><i className="fab fa-cc-stripe" aria-hidden="true" /> pay with stripe</a></li>
                                                <li className="order payu"><a href="https://hsalghanimdelivery.com/payu-order-package?package_id=3&type=new">pay with PayU</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <ul className="boxes">
                                <li className="plan-name">Premium</li>
                                <li>
                                    <div className="main-plan">
                                        <div className="plan-price1-1">KYD</div>
                                        <div className="plan-price1-2">19.99</div>
                                        <div className="clearfix" />
                                    </div>
                                </li>
                                <li className="plan-pages">Can post jobs : 30</li>
                                <li className="plan-pages">Package Duration : 90 Days</li>
                                <li className="order paypal"><a href="#" data-toggle="modal" data-target="#buypack4" className="reqbtn">Buy Now</a></li>
                            </ul>
                            <div className="modal fade" id="buypack4" tabIndex={-1} role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <i className="fas fa-times" />
                                            </button>
                                            <div className="invitereval">
                                                <h3>Please Choose Your Payment Method to Pay</h3>
                                                <div className="totalpay">Total Amount to pay: <strong>KYD19.99</strong></div>
                                                <ul className="btn2s">
                                                    <li className="order paypal"><a href="https://hsalghanimdelivery.com/order-package/4"><i className="fab fa-cc-paypal" aria-hidden="true" /> pay with paypal</a></li>
                                                    <li className="order"><a href="https://hsalghanimdelivery.com/stripe-order-form/4/new"><i className="fab fa-cc-stripe" aria-hidden="true" /> pay with stripe</a></li>
                                                    <li className="order payu"><a href="https://hsalghanimdelivery.com/payu-order-package?package_id=4&type=new">pay with PayU</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <ul className="boxes">
                                <li className="plan-name">Free package</li>
                                <li>
                                    <div className="main-plan">
                                        <div className="plan-price1-1">KYD</div>
                                        <div className="plan-price1-2">0</div>
                                        <div className="clearfix" />
                                    </div>
                                </li>
                                <li className="plan-pages">Can post jobs : 5</li>
                                <li className="plan-pages">Package Duration : 10 Days</li>
                                <li className="order paypal"><a href="#" data-toggle="modal" data-target="#buypack7" className="reqbtn">Buy Now</a></li>
                            </ul>
                            <div className="modal fade" id="buypack7" tabIndex={-1} role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <i className="fas fa-times" />
                                            </button>
                                            <div className="invitereval">
                                                <h3>Please Choose Your Payment Method to Pay</h3>
                                                <div className="totalpay">Total Amount to pay: <strong>KYD0</strong></div>
                                                <ul className="btn2s">
                                                    <li className="order paypal"><a href="https://hsalghanimdelivery.com/order-free-package/7"> Subscribe Free Package</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*-end four-paln*/}
            </div>

        </>
    )
}
export default DashBoard

function Education() {
    return (
        <>
            <div className="job-header">
                <div className="contentbox">
                    <h3>Education</h3>
                    <div className id="education_div"><ul className="educationList"><li>
                        <h4>Matriculation/O-Level - Matric in Science</h4>
                        <div className="date">2012 - Bouse</div>
                        <h5>Matric</h5>
                        <p>Computer Engineering<br />My test Institute</p>
                        <div className="clearfix" />
                    </li><li>
                            <h4>Intermediate/A-Level - Intermediate in Computer Science</h4>
                            <div className="date">2014 - San Anselmo</div>
                            <h5>ICS</h5>
                            <p>Computer Engineering, Computer Graphics<br />My test Institute 2</p>
                            <div className="clearfix" />
                        </li></ul></div>
                </div>
            </div>
        </>
    )
}
export default Education
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { MdLanguage } from "react-icons/md";

const Menus = () => {
  return (
    <>
      <div className="menus">
        <ul>
          <li className="active">
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/">Jobs</Link>
          </li>
          <li>
            <Link to="/">Companies</Link>
          </li>
          <li>
            <Link to="/">About Us</Link>
          </li>
          <li>
            <Link to="/">Blog</Link>
          </li>
          <li>
            <Link to="/">Contact Us</Link>
          </li>
          <li>
            <Link to="/">Sign In</Link>
          </li>
          <li>
            <button type="button" className="btn registerBtn">
              Register
            </button>
          </li>
        </ul>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <MdLanguage />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdownMenu">
            <Dropdown.Item href="#/action-1">عربى</Dropdown.Item>
            <Dropdown.Item href="#/action-2">English</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Español</Dropdown.Item>
            <Dropdown.Item href="#/action-3">اردو</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default Menus;

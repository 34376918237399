import React from 'react'
import Menus from './menus/Menus';
import logo from "../../../../assets/img/logo/logo.jpeg";
import { Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <>
        <nav className='nav'>
            <div className="container">
                <div className="navInfo">
                    <div className="brand">
                        <Link to="/"><img src={logo} alt="Jobs" className='img-fluid' /></Link>
                    </div>
                    <Menus />
                </div>
            </div>
        </nav>
    </>
  )
}

export default Navigation
import { Link } from "react-router-dom";
import SearchJobseakerHeader from "../../../common/searchJobseakerHeader/SearchJobseakerHeader";
import ViewDetailLeft from "./viewDetailLeft/ViewDetailLeft";
import ViewDetailRight from "./viewDetailRight/ViewDetailRight";

function ViewDetail() {
  return (
    <>
      <SearchJobseakerHeader/>
      <div className="listpgWraper">
        <div className="container">
          <div className="row jobPagetitle">
            <div className="col-lg-9">
              <div className="jobinfo">
                <h2>IOS Developer - Abaris Softech Pvt Ltd</h2>
                <div className="ptext">Date Posted: Jul 27, 2018</div>
                <div className="salary">
                  Monthly: <strong>60000 PKR - 90000 PKR</strong>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="jobButtons applybox">
                <Link to="#" className="btn apply">
                  <i className="fas fa-paper-plane" aria-hidden="true" /> Apply
                  Now
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <ViewDetailLeft />

            <ViewDetailRight />
          </div>
        </div>
      </div>
    </>
  );
}
export default ViewDetail;

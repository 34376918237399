import { useState } from "react";
import { Button, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import AboutMe from "./aboutMe/AboutMe";
import CandidateContact from "./candidateContact/CandidateContact";
import CandidateDetails from "./CandidateDetail/CandidateDetail";
import Education from "./education/Education";
import Experience from "./experience/Experience";
import Language from "./language/Languages";
import Portfolio from "./portfolio/Portfolio";
import VideoProfile from "./VideoProfile/VideoProfile";

function ApplicationProfile() {
    
    return (
        <>
            <div className="listpgWraper">
                <div className="container">
                    <div className="usercoverimg">
                        <img src="https://hsalghanimdelivery.com/user_images/-1614188466-122.jpg" alt="Daniyal Khan" title="Daniyal Khan" />
                        <div className="userMaininfo">
                            <div className="userPic"><img src="https://hsalghanimdelivery.com/admin_assets/no-image.png" alt="Daniyal Khan" title="Daniyal Khan" /></div>
                            <div className="title">
                                <h3>Daniyal Khan
                                    <span>Immediate Available For Work</span>
                                </h3>
                                <div className="desi"><i className="fa fa-map-marker" aria-hidden="true" /> Saharanpur, Uttar Pradesh, India</div>
                                <div className="membersinc"><i className="fa fa-history" aria-hidden="true" /> Member Since, Sep 19, 2018</div>
                            </div>
                        </div>
                    </div>
                    {/* Buttons */}
                    <div className="userlinkstp">
                        <a href="https://hsalghanimdelivery.com/remove-from-favourite-applicant/11/6/2/9" className="btn"><i className="fa fa-floppy-o" aria-hidden="true" /> Shortlisted </a>
                        <a style={{ color: '#fff' }} href="https://hsalghanimdelivery.com/reject-applicant-profile/11" className="btn btn-warning"><i className="fa fa-floppy-o" aria-hidden="true" /> Reject</a>
                        <a href="https://hsalghanimdelivery.com/hire-from-favourite-applicant/11/6/2/9" className="btn"><i className="fa fa-floppy-o" aria-hidden="true" /> Hire This Candidate </a>
                        <a href="https://hsalghanimdelivery.com/cvs/test-1628590240-94.pdf" className="btn"><i className="fa fa-download" aria-hidden="true" /> Download CV</a>
                        <Link to="#" className="btn"data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="fa fa-envelope" aria-hidden="true" /> Send Message</Link>
                    </div>
                   
                    {/* Job Detail start */}
                    <div className="row">
                        <div className="col-md-8">
                            {/* About Employee start */}
                            <AboutMe/>
                            {/* Profile Video start */}
                            <VideoProfile/>
                            {/* Experience start */}
                            <Experience/>
                        </div>
                        <div className="col-md-4">
                            {/* Candidate Contact */}
                            <CandidateContact/>
                            {/* Candidate Detail start */}
                            <CandidateDetails/>
                            {/* Education start */}
                            <Education/>
                            <Language/>
                        </div>
                    </div>
                    {/* Portfolio start */}
                    <Portfolio/>
                </div>
            </div>
         {/* modal */}
         <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Send Message</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
      </div>
      <div className="modal-body">
        <div className="form-group">
          <textarea className="form-control" name="message" id="message" cols={10} rows={7} defaultValue={""} />
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Submit</button>
      </div>
    </div>
  </div>
</div>

        </>
    )
}
export default ApplicationProfile
import { Link } from "react-router-dom"

function CompanyPostedJobs() {

    return (
        <>
            <div className="myads">
                <h3>Company Posted Jobs</h3>
                <ul className="searchList">
                    {/* job start */}
                    <li id="job_li_2">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="jobimg"><img src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png" alt="Abaris Softech Pvt Ltd" title="Abaris Softech Pvt Ltd" /></div>
                                <div className="jobinfo">
                                    <h3><a href="https://hsalghanimdelivery.com/job/ios-developer-2" title="IOS Developer">IOS Developer</a></h3>
                                    <div className="companyName"><a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9" title="Abaris Softech Pvt Ltd">Abaris Softech Pvt Ltd</a></div>
                                    <div className="location">
                                        <label className="fulltime" title="First Shift (Day)">First Shift (Day)</label>
                                        - <span>Auburn</span></div>
                                </div>
                                <div className="clearfix" />
                            </div>
                            <div className="col-md-5">
                                <div className="d-flex justify-content-end">
                                    <Link className="btn btn-primary me-2" to="/company/posted-jobs/list-applied-users">List Candidates</Link>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-bars dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" fdprocessedid="3vzq2j">
                                            <i className="fas fa-ellipsis-v" />
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-favourite-applied-users/2">List Short Listed Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-hired-users/2">List Hired Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-rejected-users/2">List Rejected Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/edit-front-job/2">Edit</a></li>
                                            <li><a className="dropdown-item" href="javascript:;" onClick="deleteJob(2);">Delete</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    {/* job end */}
                    <li id="job_li_25">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="jobimg"><img src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png" alt="Abaris Softech Pvt Ltd" title="Abaris Softech Pvt Ltd" /></div>
                                <div className="jobinfo">
                                    <h3><a href="https://hsalghanimdelivery.com/job/project-manager-25" title="Project Manager">Project Manager</a></h3>
                                    <div className="companyName"><a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9" title="Abaris Softech Pvt Ltd">Abaris Softech Pvt Ltd</a></div>
                                    <div className="location">
                                        <label className="fulltime" title="First Shift (Day)">First Shift (Day)</label>
                                        - <span>Kaneohe Station</span></div>
                                </div>
                                <div className="clearfix" />
                            </div>
                            <div className="col-md-5">
                                <div className="d-flex justify-content-end">
                                    <a className="btn btn-primary me-2" href="https://hsalghanimdelivery.com/list-applied-users/25">List Candidates</a>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-bars dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" fdprocessedid="v9u7s2k">
                                            <i className="fas fa-ellipsis-v" />
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-favourite-applied-users/25">List Short Listed Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-hired-users/25">List Hired Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-rejected-users/25">List Rejected Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/edit-front-job/25">Edit</a></li>
                                            <li><a className="dropdown-item" href="javascript:;" onClick="deleteJob(25);">Delete</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    {/* job end */}
                    <li id="job_li_30">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="jobimg"><img src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png" alt="Abaris Softech Pvt Ltd" title="Abaris Softech Pvt Ltd" /></div>
                                <div className="jobinfo">
                                    <h3><a href="https://hsalghanimdelivery.com/job/full-stack-designer-30" title="Full Stack Designer">Full Stack Designer</a></h3>
                                    <div className="companyName"><a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9" title="Abaris Softech Pvt Ltd">Abaris Softech Pvt Ltd</a></div>
                                    <div className="location">
                                        <label className="fulltime" title="Third Shift (Night)">Third Shift (Night)</label>
                                        - <span>al-Ahmadi</span></div>
                                </div>
                                <div className="clearfix" />
                            </div>
                            <div className="col-md-5">
                                <div className="d-flex justify-content-end">
                                    <a className="btn btn-primary me-2" href="https://hsalghanimdelivery.com/list-applied-users/30">List Candidates</a>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-bars dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" fdprocessedid="y11dz">
                                            <i className="fas fa-ellipsis-v" />
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-favourite-applied-users/30">List Short Listed Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-hired-users/30">List Hired Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-rejected-users/30">List Rejected Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/edit-front-job/30">Edit</a></li>
                                            <li><a className="dropdown-item" href="javascript:;" onClick="deleteJob(30);">Delete</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    {/* job end */}
                    <li id="job_li_31">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="jobimg"><img src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png" alt="Abaris Softech Pvt Ltd" title="Abaris Softech Pvt Ltd" /></div>
                                <div className="jobinfo">
                                    <h3><a href="https://hsalghanimdelivery.com/job/testte-31" title="Full Stack Developer Required">Full Stack Developer Required</a></h3>
                                    <div className="companyName"><a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9" title="Abaris Softech Pvt Ltd">Abaris Softech Pvt Ltd</a></div>
                                    <div className="location">
                                        <label className="fulltime" title="Second Shift (Afternoon)">Second Shift (Afternoon)</label>
                                        - <span>Bessemer</span></div>
                                </div>
                                <div className="clearfix" />
                            </div>
                            <div className="col-md-5">
                                <div className="d-flex justify-content-end">
                                    <a className="btn btn-primary me-2" href="https://hsalghanimdelivery.com/list-applied-users/31">List Candidates</a>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-bars dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" fdprocessedid="9zj7mt">
                                            <i className="fas fa-ellipsis-v" />
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-favourite-applied-users/31">List Short Listed Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-hired-users/31">List Hired Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-rejected-users/31">List Rejected Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/edit-front-job/31">Edit</a></li>
                                            <li><a className="dropdown-item" href="javascript:;" onClick="deleteJob(31);">Delete</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    {/* job end */}
                    <li id="job_li_32">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="jobimg"><img src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png" alt="Abaris Softech Pvt Ltd" title="Abaris Softech Pvt Ltd" /></div>
                                <div className="jobinfo">
                                    <h3><a href="https://hsalghanimdelivery.com/job/testte-32" title="Graphic Designer Required">Graphic Designer Required</a></h3>
                                    <div className="companyName"><a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9" title="Abaris Softech Pvt Ltd">Abaris Softech Pvt Ltd</a></div>
                                    <div className="location">
                                        <label className="fulltime" title="Second Shift (Afternoon)">Second Shift (Afternoon)</label>
                                        - <span>Bessemer</span></div>
                                </div>
                                <div className="clearfix" />
                            </div>
                            <div className="col-md-5">
                                <div className="d-flex justify-content-end">
                                    <a className="btn btn-primary me-2" href="https://hsalghanimdelivery.com/list-applied-users/32">List Candidates</a>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-bars dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" fdprocessedid="90b3fe">
                                            <i className="fas fa-ellipsis-v" />
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-favourite-applied-users/32">List Short Listed Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-hired-users/32">List Hired Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-rejected-users/32">List Rejected Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/edit-front-job/32">Edit</a></li>
                                            <li><a className="dropdown-item" href="javascript:;" onClick="deleteJob(32);">Delete</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    {/* job end */}
                    <li id="job_li_33">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="jobimg"><img src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png" alt="Abaris Softech Pvt Ltd" title="Abaris Softech Pvt Ltd" /></div>
                                <div className="jobinfo">
                                    <h3><a href="https://hsalghanimdelivery.com/job/seo-expert-33" title="SEO Expert">SEO Expert</a></h3>
                                    <div className="companyName"><a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9" title="Abaris Softech Pvt Ltd">Abaris Softech Pvt Ltd</a></div>
                                    <div className="location">
                                        <label className="fulltime" title="Second Shift (Afternoon)">Second Shift (Afternoon)</label>
                                        - <span>Casas Adobes</span></div>
                                </div>
                                <div className="clearfix" />
                            </div>
                            <div className="col-md-5">
                                <div className="d-flex justify-content-end">
                                    <a className="btn btn-primary me-2" href="https://hsalghanimdelivery.com/list-applied-users/33">List Candidates</a>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-bars dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" fdprocessedid="m7b61">
                                            <i className="fas fa-ellipsis-v" />
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-favourite-applied-users/33">List Short Listed Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-hired-users/33">List Hired Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/list-rejected-users/33">List Rejected Candidates</a></li>
                                            <li><a className="dropdown-item" href="https://hsalghanimdelivery.com/edit-front-job/33">Edit</a></li>
                                            <li><a className="dropdown-item" href="javascript:;" onClick="deleteJob(33);">Delete</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    {/* job end */}
                </ul>
                {/* Pagination Start */}
                <div className="pagiWrap">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="showreslt">
                                Showing Jobs : 1 - 6 Total 6
                            </div>
                        </div>
                        <div className="col-md-7 text-right">
                        </div>
                    </div>
                </div>
                {/* Pagination end */}
            </div>

        </>
    )
}
export default CompanyPostedJobs
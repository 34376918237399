import RequiredSkills from "../../../../../common/requiredSkills/RequiredSkills";
import SearchJobseakerHeader from "../../../../../common/searchJobseakerHeader/SearchJobseakerHeader";

function JavaScriptSkill() {
  return (
    <>
      <SearchJobseakerHeader />
      <RequiredSkills />
    </>
  );
}
export default JavaScriptSkill;


function CandidateDetails() {
    return (
        <>
        <div className="job-header">
                                <div className="jobdetail">
                                    <h3>Candidate Detail</h3>
                                    <ul className="jbdetail">
                                        <li className="row">
                                            <div className="col-md-6 col-xs-6">Is Email Verified</div>
                                            <div className="col-md-6 col-xs-6"><span>Yes</span></div>
                                        </li>
                                        <li className="row">
                                            <div className="col-md-6 col-xs-6">Immediate Available</div>
                                            <div className="col-md-6 col-xs-6"><span>Yes</span></div>
                                        </li>
                                        <li className="row">
                                            <div className="col-md-6 col-xs-6">Age</div>
                                            <div className="col-md-6 col-xs-6"><span>13 Years</span></div>
                                        </li>
                                        <li className="row">
                                            <div className="col-md-6 col-xs-6">Gender</div>
                                            <div className="col-md-6 col-xs-6"><span>Male</span></div>
                                        </li>
                                        <li className="row">
                                            <div className="col-md-6 col-xs-6">Marital Status</div>
                                            <div className="col-md-6 col-xs-6"><span>Single</span></div>
                                        </li>
                                        <li className="row">
                                            <div className="col-md-6 col-xs-6">Experience</div>
                                            <div className="col-md-6 col-xs-6"><span>6 Year</span></div>
                                        </li>
                                        <li className="row">
                                            <div className="col-md-6 col-xs-6">Career Level</div>
                                            <div className="col-md-6 col-xs-6"><span>Experienced Professional</span></div>
                                        </li>
                                        <li className="row">
                                            <div className="col-md-6 col-xs-6">Current Salary</div>
                                            <div className="col-md-6 col-xs-6"><span className="permanent">10000 INR</span></div>
                                        </li>
                                        <li className="row">
                                            <div className="col-md-6 col-xs-6">Expected Salary</div>
                                            <div className="col-md-6 col-xs-6"><span className="freelance">15000 INR</span></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
        </>
    )
}
export default CandidateDetails
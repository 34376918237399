
function UnlockUsers() {
    return (
        <>
            <div className="myads">
                <h3>Unlocked Seekers</h3>
                <ul className="searchList">
                    {/* job start */}
                </ul>
            </div>

        </>
    )
}
export default UnlockUsers
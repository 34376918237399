import { FaCcPaypal } from 'react-icons/fa';
import { FaCcStripe } from 'react-icons/fa';
function CvSearchPackage() {
    return (
        <>
            <div className="instoretxt">
                <div className="credit">Your Package is: <strong>Test CVS - KYD30</strong></div>
                <div className="credit">Package Duration : <strong>27 Mar, 2021</strong> - <strong>27 Mar, 2021</strong></div>
                <div className="credit">Availed quota : <strong>4</strong> - <strong>30</strong></div>
            </div>
            <div className="paypackages">
                {/*-four-paln*/}
                <div className="four-plan">
                    <h3>Upgrade Package</h3>
                    <div className="row">             <div className="col-md-4 col-sm-6 col-xs-12">
                        <ul className="boxes">
                            <li className="icon"><i className="fa fa-paper-plane" aria-hidden="true" /></li>
                            <li className="plan-name">Test CVS</li>
                            <li>
                                <div className="main-plan">
                                    <div className="plan-price1-1">$</div>
                                    <div className="plan-price1-2">30</div>
                                    <div className="clearfix" />
                                </div>
                            </li>
                            <li className="plan-pages">Can search seekrs : 30</li>
                            <li className="plan-pages">Package Duration : 30 Days</li>
                            <li className="order paypal">
                                <a href="https://hsalghanimdelivery.com/order-upgrade-package/8">
                                    <FaCcPaypal/>
                                     pay with paypal
                                     </a>
                                     </li>
                            <li className="order">
                                <a href="https://hsalghanimdelivery.com/stripe-order-form/8/upgrade">
                                    <FaCcStripe/> 
                                    pay with stripe
                                    </a>
                                    </li>
                            <li className="order payu"><a href="https://hsalghanimdelivery.com/payu-order-cvsearch-package?package_id=8&type=upgrade">pay with PayU</a></li>
                        </ul>
                    </div>
                    </div>
                </div>
                {/*-end four-paln*/}
            </div>

        </>
    )
}
export default CvSearchPackage
import { Link } from "react-router-dom";

function RequiredSkills() {
  return (
    <>
      <div className="listpgWraper">
        <div className="container">
          <form action="https://hsalghanimdelivery.com/jobs" method="get">
            {/* Search Result and sidebar start */}
            <div className="row">
              <div className="col-lg-3">
                <div className="jobreqbtn">
                  <a
                    className="btn btn-job-alert-disabled"
                    disabled
                    href="javascript:;"
                  >
                    <i
                      className="fa fa-bell"
                      style={{ fontSize: "1.125rem" }}
                    />{" "}
                    Save Job Alert
                  </a>
                  <a
                    href="https://hsalghanimdelivery.com/post-job"
                    className="btn"
                  >
                    <i className="fa fa-file-text" aria-hidden="true" /> Post
                    Job
                  </a>
                </div>
                {/* Side Bar start */}
                <div className="sidebar">
                  <input type="hidden" name="search" defaultValue />
                  {/* Jobs By Title */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Title</h4>
                    <ul className="optionlist view_more_ul hide_vm_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="job_title[]"
                          id="job_title_0"
                          defaultValue="IOS Developer"
                        />
                        <label htmlFor="job_title_0" />
                        IOS Developer <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_title[]"
                          id="job_title_1"
                          defaultValue="Laravel Developer"
                        />
                        <label htmlFor="job_title_1" />
                        Laravel Developer <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_title[]"
                          id="job_title_2"
                          defaultValue="Web Designer"
                        />
                        <label htmlFor="job_title_2" />
                        Web Designer <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_title[]"
                          id="job_title_3"
                          defaultValue="Frontend Developer"
                        />
                        <label htmlFor="job_title_3" />
                        Frontend Developer <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_title[]"
                          id="job_title_4"
                          defaultValue="Wordpress Developer"
                        />
                        <label htmlFor="job_title_4" />
                        Wordpress Developer <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_title[]"
                          id="job_title_5"
                          defaultValue="Graphic Designer"
                        />
                        <label htmlFor="job_title_5" />
                        Graphic Designer <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_title[]"
                          id="job_title_6"
                          defaultValue="SEO Expert"
                        />
                        <label htmlFor="job_title_6" />
                        SEO Expert <span>2</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_title[]"
                          id="job_title_7"
                          defaultValue="Full Stack Designer"
                        />
                        <label htmlFor="job_title_7" />
                        Full Stack Designer <span>1</span>{" "}
                      </li>
                    </ul>
                    {/* title end */}
                    <span className="text text-primary view_more">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By Country */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Country</h4>
                    <ul className="optionlist view_more_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="country_id[]"
                          id="country_231"
                          defaultValue={231}
                        />
                        <label htmlFor="country_231" />
                        United States of America <span>16</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="country_id[]"
                          id="country_117"
                          defaultValue={117}
                        />
                        <label htmlFor="country_117" />
                        Kuwait <span>2</span>{" "}
                      </li>
                    </ul>
                    <span className="text text-primary view_more hide_vm">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By Country end*/}
                  {/* Jobs By State */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By State</h4>
                    <ul className="optionlist view_more_ul hide_vm_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="state_id[]"
                          id="state_3919"
                          defaultValue={3919}
                        />
                        <label htmlFor="state_3919" />
                        Alabama <span>4</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="state_id[]"
                          id="state_3926"
                          defaultValue={3926}
                        />
                        <label htmlFor="state_3926" />
                        Colorado <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="state_id[]"
                          id="state_3928"
                          defaultValue={3928}
                        />
                        <label htmlFor="state_3928" />
                        Delaware <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="state_id[]"
                          id="state_3935"
                          defaultValue={3935}
                        />
                        <label htmlFor="state_3935" />
                        Indiana <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="state_id[]"
                          id="state_2091"
                          defaultValue={2091}
                        />
                        <label htmlFor="state_2091" />
                        al-Kuwayt <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="state_id[]"
                          id="state_3942"
                          defaultValue={3942}
                        />
                        <label htmlFor="state_3942" />
                        Maryland <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="state_id[]"
                          id="state_3975"
                          defaultValue={3975}
                        />
                        <label htmlFor="state_3975" />
                        Washington <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="state_id[]"
                          id="state_2088"
                          defaultValue={2088}
                        />
                        <label htmlFor="state_2088" />
                        al-Ahmadi <span>1</span>{" "}
                      </li>
                    </ul>
                    <span className="text text-primary view_more">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By State end*/}
                  {/* Jobs By City */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By City</h4>
                    <ul className="optionlist view_more_ul hide_vm_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="city_id[]"
                          id="city_42602"
                          defaultValue={42602}
                        />
                        <label htmlFor="city_42602" />
                        Auburn <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="city_id[]"
                          id="city_43396"
                          defaultValue={43396}
                        />
                        <label htmlFor="city_43396" />
                        Canon City <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="city_id[]"
                          id="city_43579"
                          defaultValue={43579}
                        />
                        <label htmlFor="city_43579" />
                        Milton <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="city_id[]"
                          id="city_44403"
                          defaultValue={44403}
                        />
                        <label htmlFor="city_44403" />
                        Clarksville <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="city_id[]"
                          id="city_25833"
                          defaultValue={25833}
                        />
                        <label htmlFor="city_25833" />
                        ad-Dawhah <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="city_id[]"
                          id="city_44755"
                          defaultValue={44755}
                        />
                        <label htmlFor="city_44755" />
                        Arnold <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="city_id[]"
                          id="city_46777"
                          defaultValue={46777}
                        />
                        <label htmlFor="city_46777" />
                        Blaine <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="city_id[]"
                          id="city_25787"
                          defaultValue={25787}
                        />
                        <label htmlFor="city_25787" />
                        al-Ahmadi <span>1</span>{" "}
                      </li>
                    </ul>
                    <span className="text text-primary view_more">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By City end*/}
                  {/* Jobs By Experience */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Experience</h4>
                    <ul className="optionlist view_more_ul hide_vm_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="job_experience_id[]"
                          id="job_experience_5"
                          defaultValue={5}
                        />
                        <label htmlFor="job_experience_5" />4 Year{" "}
                        <span>3</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_experience_id[]"
                          id="job_experience_4"
                          defaultValue={4}
                        />
                        <label htmlFor="job_experience_4" />3 Year{" "}
                        <span>4</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_experience_id[]"
                          id="job_experience_3"
                          defaultValue={3}
                        />
                        <label htmlFor="job_experience_3" />2 Year{" "}
                        <span>3</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_experience_id[]"
                          id="job_experience_6"
                          defaultValue={6}
                        />
                        <label htmlFor="job_experience_6" />5 Year{" "}
                        <span>5</span>{" "}
                      </li>
                    </ul>
                    <span className="text text-primary view_more">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By Experience end */}
                  {/* Jobs By Job Type */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Job Type</h4>
                    <ul className="optionlist view_more_ul hide_vm_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="job_type_id[]"
                          id="job_type_3"
                          defaultValue={3}
                        />
                        <label htmlFor="job_type_3" />
                        Full Time/Permanent <span>8</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_type_id[]"
                          id="job_type_2"
                          defaultValue={2}
                        />
                        <label htmlFor="job_type_2" />
                        Freelance <span>2</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_type_id[]"
                          id="job_type_4"
                          defaultValue={4}
                        />
                        <label htmlFor="job_type_4" />
                        Internship <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_type_id[]"
                          id="job_type_5"
                          defaultValue={5}
                        />
                        <label htmlFor="job_type_5" />
                        Part Time <span>1</span>{" "}
                      </li>
                    </ul>
                    <span className="text text-primary view_more">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By Job Type end */}
                  {/* Jobs By Job Shift */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Job Shift</h4>
                    <ul className="optionlist view_more_ul hide_vm_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="job_shift_id[]"
                          id="job_shift_1"
                          defaultValue={1}
                        />
                        <label htmlFor="job_shift_1" />
                        First Shift (Day) <span>12</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_shift_id[]"
                          id="job_shift_2"
                          defaultValue={2}
                        />
                        <label htmlFor="job_shift_2" />
                        Second Shift (Afternoon) <span>4</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_shift_id[]"
                          id="job_shift_3"
                          defaultValue={3}
                        />
                        <label htmlFor="job_shift_3" />
                        Third Shift (Night) <span>2</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_shift_id[]"
                          id="job_shift_4"
                          defaultValue={4}
                        />
                        <label htmlFor="job_shift_4" />
                        Rotating <span>1</span>{" "}
                      </li>
                    </ul>
                    <span className="text text-primary view_more">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By Job Shift end */}
                  {/* Jobs By Career Level */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Career Level</h4>
                    <ul className="optionlist view_more_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="career_level_id[]"
                          id="career_level_3"
                          defaultValue={3}
                        />
                        <label htmlFor="career_level_3" />
                        Experienced Professional <span />{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="career_level_id[]"
                          id="career_level_2"
                          defaultValue={2}
                        />
                        <label htmlFor="career_level_2" />
                        Entry Level <span />{" "}
                      </li>
                    </ul>
                    <span className="text text-primary view_more hide_vm">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By Career Level end */}
                  {/* Jobs By Degree Level */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Degree Level</h4>
                    <ul className="optionlist view_more_ul hide_vm_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="degree_level_id[]"
                          id="degree_level_4"
                          defaultValue={4}
                        />
                        <label htmlFor="degree_level_4" />
                        Bachelors <span>7</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="degree_level_id[]"
                          id="degree_level_3"
                          defaultValue={3}
                        />
                        <label htmlFor="degree_level_3" />
                        Intermediate/A-Level <span>3</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="degree_level_id[]"
                          id="degree_level_2"
                          defaultValue={2}
                        />
                        <label htmlFor="degree_level_2" />
                        Matriculation/O-Level <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="degree_level_id[]"
                          id="degree_level_8"
                          defaultValue={8}
                        />
                        <label htmlFor="degree_level_8" />
                        Certification <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="degree_level_id[]"
                          id="degree_level_5"
                          defaultValue={5}
                        />
                        <label htmlFor="degree_level_5" />
                        Masters <span>4</span>{" "}
                      </li>
                    </ul>
                    <span className="text text-primary view_more">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By Degree Level end */}
                  {/* Jobs By Gender */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Gender</h4>
                    <ul className="optionlist view_more_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="gender_id[]"
                          id="gender_2"
                          defaultValue={2}
                        />
                        <label htmlFor="gender_2" />
                        Male <span>11</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="gender_id[]"
                          id="gender_1"
                          defaultValue={1}
                        />
                        <label htmlFor="gender_1" />
                        Female <span>3</span>{" "}
                      </li>
                    </ul>
                    <span className="text text-primary view_more hide_vm">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By Gender end */}
                  {/* Jobs By Industry */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Industry</h4>
                    <ul className="optionlist view_more_ul hide_vm_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="industry_id[]"
                          id="industry_31"
                          defaultValue={31}
                        />
                        <label htmlFor="industry_31" />
                        AutoMobile <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="industry_id[]"
                          id="industry_1"
                          defaultValue={1}
                        />
                        <label htmlFor="industry_1" />
                        Information Technology <span>8</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="industry_id[]"
                          id="industry_7"
                          defaultValue={7}
                        />
                        <label htmlFor="industry_7" />
                        Advertising/PR <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="industry_id[]"
                          id="industry_24"
                          defaultValue={24}
                        />
                        <label htmlFor="industry_24" />
                        Travel/Tourism/Transportation <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="industry_id[]"
                          id="industry_11"
                          defaultValue={11}
                        />
                        <label htmlFor="industry_11" />
                        Education/Training <span>1</span>{" "}
                      </li>
                    </ul>
                    <span className="text text-primary view_more">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By Industry end */}
                  {/* Jobs By Skill */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Skill</h4>
                    <ul className="optionlist view_more_ul hide_vm_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="job_skill_id[]"
                          id="job_skill_2"
                          defaultValue={2}
                        />
                        <label htmlFor="job_skill_2" />
                        Adobe Photoshop <span>5</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_skill_id[]"
                          id="job_skill_6"
                          defaultValue={6}
                        />
                        <label htmlFor="job_skill_6" />
                        CSS <span>8</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_skill_id[]"
                          id="job_skill_8"
                          defaultValue={8}
                          defaultChecked='"checked"'
                        />
                        <label htmlFor="job_skill_8" />
                        HTML <span>8</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_skill_id[]"
                          id="job_skill_10"
                          defaultValue={10}
                        />
                        <label htmlFor="job_skill_10" />
                        JavaScript <span>5</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_skill_id[]"
                          id="job_skill_11"
                          defaultValue={11}
                        />
                        <label htmlFor="job_skill_11" />
                        Jquery <span>2</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_skill_id[]"
                          id="job_skill_15"
                          defaultValue={15}
                        />
                        <label htmlFor="job_skill_15" />
                        MySQL <span>4</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_skill_id[]"
                          id="job_skill_16"
                          defaultValue={16}
                        />
                        <label htmlFor="job_skill_16" />
                        PHP <span>5</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_skill_id[]"
                          id="job_skill_1"
                          defaultValue={1}
                        />
                        <label htmlFor="job_skill_1" />
                        Adobe Illustrator <span>1</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_skill_id[]"
                          id="job_skill_4"
                          defaultValue={4}
                        />
                        <label htmlFor="job_skill_4" />
                        COMMUNICATION <span>2</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_skill_id[]"
                          id="job_skill_12"
                          defaultValue={12}
                        />
                        <label htmlFor="job_skill_12" />
                        Marketing <span>3</span>{" "}
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="job_skill_id[]"
                          id="job_skill_19"
                          defaultValue={19}
                        />
                        <label htmlFor="job_skill_19" />
                        WordPress <span>2</span>{" "}
                      </li>
                    </ul>
                    <span className="text text-primary view_more">
                      View More
                    </span>{" "}
                  </div>
                  {/* Jobs By Industry end */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Functional Areas</h4>
                    <ul className="optionlist view_more_ul hide_vm_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="functional_area_id[]"
                          id="functional_area_id_128"
                          defaultValue={128}
                        />
                        <label htmlFor="functional_area_id_128" />
                        Software &amp; Web Development <span>1</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="functional_area_id[]"
                          id="functional_area_id_144"
                          defaultValue={144}
                        />
                        <label htmlFor="functional_area_id_144" />
                        Web Developer <span>2</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="functional_area_id[]"
                          id="functional_area_id_28"
                          defaultValue={28}
                        />
                        <label htmlFor="functional_area_id_28" />
                        Database Administration (DBA) <span>1</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="functional_area_id[]"
                          id="functional_area_id_8"
                          defaultValue={8}
                        />
                        <label htmlFor="functional_area_id_8" />
                        Advertising <span>1</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="functional_area_id[]"
                          id="functional_area_id_23"
                          defaultValue={23}
                        />
                        <label htmlFor="functional_area_id_23" />
                        Creative Design <span>3</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="functional_area_id[]"
                          id="functional_area_id_40"
                          defaultValue={40}
                        />
                        <label htmlFor="functional_area_id_40" />
                        Graphic Design <span>1</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="functional_area_id[]"
                          id="functional_area_id_69"
                          defaultValue={69}
                        />
                        <label htmlFor="functional_area_id_69" />
                        Marketing <span>1</span>
                      </li>
                    </ul>
                    {/* title end */}
                    <span className="text text-primary view_more">
                      View More
                    </span>{" "}
                  </div>
                  {/* Top Companies */}
                  <div className="widget">
                    <h4 className="widget-title">Jobs By Company</h4>
                    <ul className="optionlist view_more_ul hide_vm_ul">
                      <li>
                        <input
                          type="checkbox"
                          name="company_id[]"
                          id="company_9"
                          defaultValue={9}
                        />
                        <label htmlFor="company_9" />
                        Abaris Softech Pvt Ltd <span>6</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="company_id[]"
                          id="company_8"
                          defaultValue={8}
                        />
                        <label htmlFor="company_8" />
                        Travel Advisor <span>1</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="company_id[]"
                          id="company_3"
                          defaultValue={3}
                        />
                        <label htmlFor="company_3" />
                        New Design Studio <span>1</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="company_id[]"
                          id="company_11"
                          defaultValue={11}
                        />
                        <label htmlFor="company_11" />
                        Sphere <span>1</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="company_id[]"
                          id="company_7"
                          defaultValue={7}
                        />
                        <label htmlFor="company_7" />
                        Connect People <span>1</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="company_id[]"
                          id="company_6"
                          defaultValue={6}
                        />
                        <label htmlFor="company_6" />
                        Media Wave <span>1</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="company_id[]"
                          id="company_14"
                          defaultValue={14}
                        />
                        <label htmlFor="company_14" />
                        Mayan Design Studios <span>1</span>
                      </li>
                    </ul>
                    <span className="text text-primary view_more">
                      View More
                    </span>{" "}
                  </div>
                  {/* Top Companies end */}
                  {/* Salary */}
                  <div className="widget">
                    <h4 className="widget-title">Salary Range</h4>
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="salary_from"
                        placeholder="Salary From"
                        name="salary_from"
                        type="number"
                        fdprocessedid="hpqne"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        id="salary_to"
                        placeholder="Salary To"
                        name="salary_to"
                        type="number"
                        fdprocessedid="y8m179"
                      />
                    </div>
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="salary_currency"
                        name="salary_currency"
                        fdprocessedid="6rgis"
                      >
                        <option value selected="selected">
                          Select Salary Currency
                        </option>
                        <option value="AED">AED</option>
                        <option value="AF">AF</option>
                        <option value="ALL">ALL</option>
                        <option value="ANG">ANG</option>
                        <option value="ARS">ARS</option>
                        <option value="AUD">AUD</option>
                        <option value="AWG">AWG</option>
                        <option value="AZ">AZ</option>
                        <option value="BAM">BAM</option>
                        <option value="BBD">BBD</option>
                        <option value="BG">BG</option>
                        <option value="BMD">BMD</option>
                        <option value="BOB">BOB</option>
                        <option value="BRL">BRL</option>
                        <option value="BWP">BWP</option>
                        <option value="BYR">BYR</option>
                        <option value="CAD">CAD</option>
                        <option value="CHF">CHF</option>
                        <option value="CLP">CLP</option>
                        <option value="CNY">CNY</option>
                        <option value="COP">COP</option>
                        <option value="CRC">CRC</option>
                        <option value="CUP">CUP</option>
                        <option value="CZK">CZK</option>
                        <option value="DKK">DKK</option>
                        <option value="DOP ">DOP </option>
                        <option value="EGP">EGP</option>
                        <option value="EUR">EUR</option>
                        <option value="FKP">FKP</option>
                        <option value="GBP">GBP</option>
                        <option value="GHC">GHC</option>
                        <option value="GIP">GIP</option>
                        <option value="GTQ">GTQ</option>
                        <option value="GYD">GYD</option>
                        <option value="HNL">HNL</option>
                        <option value="HUF">HUF</option>
                        <option value="IDR">IDR</option>
                        <option value="ILS">ILS</option>
                        <option value="INR">INR</option>
                        <option value="IRR">IRR</option>
                        <option value="ISK">ISK</option>
                        <option value="JEP">JEP</option>
                        <option value="JMD">JMD</option>
                        <option value="JPY">JPY</option>
                        <option value="KGS">KGS</option>
                        <option value="KHR">KHR</option>
                        <option value="KYD">KYD</option>
                        <option value="KZT">KZT</option>
                        <option value="LAK">LAK</option>
                        <option value="LBP">LBP</option>
                        <option value="LKR">LKR</option>
                        <option value="LRD">LRD</option>
                        <option value="LTL">LTL</option>
                        <option value="LVL">LVL</option>
                        <option value="MKD">MKD</option>
                        <option value="MNT">MNT</option>
                        <option value="MUR">MUR</option>
                        <option value="MX">MX</option>
                        <option value="MYR">MYR</option>
                        <option value="MZ">MZ</option>
                        <option value="NAD">NAD</option>
                        <option value="NG">NG</option>
                        <option value="NIO">NIO</option>
                        <option value="NOK">NOK</option>
                        <option value="NPR">NPR</option>
                        <option value="NZD">NZD</option>
                        <option value="OMR">OMR</option>
                        <option value="PAB">PAB</option>
                        <option value="PE">PE</option>
                        <option value="PHP">PHP</option>
                        <option value="PKR">PKR</option>
                        <option value="PL">PL</option>
                        <option value="PYG">PYG</option>
                        <option value="QAR">QAR</option>
                        <option value="RO">RO</option>
                        <option value="RSD">RSD</option>
                        <option value="RUB">RUB</option>
                        <option value="SAR">SAR</option>
                        <option value="SBD">SBD</option>
                        <option value="SCR">SCR</option>
                        <option value="SEK">SEK</option>
                        <option value="SGD">SGD</option>
                        <option value="SHP">SHP</option>
                        <option value="SOS">SOS</option>
                        <option value="SRD">SRD</option>
                        <option value="SVC">SVC</option>
                        <option value="SYP">SYP</option>
                        <option value="THB">THB</option>
                        <option value="TRY">TRY</option>
                        <option value="TTD">TTD</option>
                        <option value="TVD">TVD</option>
                        <option value="TWD">TWD</option>
                        <option value="UAH">UAH</option>
                        <option value="USD">USD</option>
                        <option value="UYU">UYU</option>
                        <option value="UZS">UZS</option>
                        <option value="VEF">VEF</option>
                        <option value="VND">VND</option>
                        <option value="YER">YER</option>
                        <option value="ZAR">ZAR</option>
                        <option value="ZWD">ZWD</option>
                      </select>
                    </div>
                    {/* Salary end */}
                    {/* button */}
                    <div className="searchnt">
                      <button
                        type="submit"
                        className="btn"
                        fdprocessedid="wo7alr"
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                        Jobs
                      </button>
                    </div>
                    {/* button end*/}
                  </div>
                  {/* Side Bar end */}
                </div>
              </div>
              <div className="col-lg-9">
                {/* Search List */}
                <div className="topstatinfo">Showing Jobs : 1 - 8 Total 8</div>
                <ul className="searchList">
                  {/* job start */}
                  <li className=" featured ">
                    <div className="row">
                      <div className="col-lg-10 col-md-8">
                        <div className="jobimg">
                          <img
                            src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                            alt="Abaris Softech Pvt Ltd"
                            title="Abaris Softech Pvt Ltd"
                          />
                        </div>
                        <div className="jobinfo">
                          <h3>
                            <a
                              href="https://hsalghanimdelivery.com/job/full-stack-designer-30"
                              title="Full Stack Designer"
                            >
                              Full Stack Designer
                            </a>{" "}
                            <i
                              className="fas fa-bolt"
                              title="This Job is Featured"
                            />{" "}
                          </h3>
                          <div className="companyName">
                            <a
                              href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                              title="Abaris Softech Pvt Ltd"
                            >
                              Abaris Softech Pvt Ltd
                            </a>
                          </div>
                          <div className="location">
                            <label
                              className="fulltime"
                              title="Full Time/Permanent"
                            >
                              Full Time/Permanent
                            </label>
                            - <span>al-Ahmadi</span>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="col-lg-2 col-md-4">
                        <div className="listbtn">
                          <Link to="/ios-developer-2">View Details</Link>
                        </div>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed pretium nunc non justo placerat pulvinar. Vestibulum
                      ac ullamcorper sapien, nec scelerisq...
                    </p>
                  </li>
                  {/* job end */}
                  <li className=" featured ">
                    <div className="row">
                      <div className="col-lg-10 col-md-8">
                        <div className="jobimg">
                          <img
                            src="https://hsalghanimdelivery.com/admin_assets/no-image.png"
                            alt="Mayan Design Studios"
                            title="Mayan Design Studios"
                          />
                        </div>
                        <div className="jobinfo">
                          <h3>
                            <a
                              href="https://hsalghanimdelivery.com/job/seo-expert-28"
                              title="SEO Expert"
                            >
                              SEO Expert
                            </a>{" "}
                            <i
                              className="fas fa-bolt"
                              title="This Job is Featured"
                            />{" "}
                          </h3>
                          <div className="companyName">
                            <a
                              href="https://hsalghanimdelivery.com/company/mayan-design-studios-14"
                              title="Mayan Design Studios"
                            >
                              Mayan Design Studios
                            </a>
                          </div>
                          <div className="location">
                            <label
                              className="fulltime"
                              title="Full Time/Permanent"
                            >
                              Full Time/Permanent
                            </label>
                            - <span>Blaine</span>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="col-lg-2 col-md-4">
                        <div className="listbtn">
                          <Link to="/ios-developer-2">View Details</Link>
                        </div>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed pretium nunc non justo placerat pulvinar. Vestibulum
                      ac ullamcorper sapien, nec scelerisq...
                    </p>
                  </li>
                  {/* job end */}
                  <li className=" featured ">
                    <div className="row">
                      <div className="col-lg-10 col-md-8">
                        <div className="jobimg">
                          <img
                            src="https://hsalghanimdelivery.com/company_logos/media-wave-1536851777-515.jpg"
                            alt="Media Wave"
                            title="Media Wave"
                          />
                        </div>
                        <div className="jobinfo">
                          <h3>
                            <a
                              href="https://hsalghanimdelivery.com/job/graphic-designer-26"
                              title="Graphic Designer"
                            >
                              Graphic Designer
                            </a>{" "}
                            <i
                              className="fas fa-bolt"
                              title="This Job is Featured"
                            />{" "}
                          </h3>
                          <div className="companyName">
                            <a
                              href="https://hsalghanimdelivery.com/company/media-wave-6"
                              title="Media Wave"
                            >
                              Media Wave
                            </a>
                          </div>
                          <div className="location">
                            <label className="fulltime" title="Freelance">
                              Freelance
                            </label>
                            - <span>Arnold</span>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="col-lg-2 col-md-4">
                        <div className="listbtn">
                          <Link to="/ios-developer-2">View Details</Link>
                        </div>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed pretium nunc non justo placerat pulvinar. Vestibulum
                      ac ullamcorper sapien, nec scelerisq...
                    </p>
                  </li>
                  {/* job end */}
                  <li className=" featured ">
                    <div className="row">
                      <div className="col-lg-10 col-md-8">
                        <div className="jobimg">
                          <img
                            src="https://hsalghanimdelivery.com/admin_assets/no-image.png"
                            alt="Connect People"
                            title="Connect People"
                          />
                        </div>
                        <div className="jobinfo">
                          <h3>
                            <a
                              href="https://hsalghanimdelivery.com/job/wordpress-developer-23"
                              title="Wordpress Developer"
                            >
                              Wordpress Developer
                            </a>{" "}
                            <i
                              className="fas fa-bolt"
                              title="This Job is Featured"
                            />{" "}
                          </h3>
                          <div className="companyName">
                            <a
                              href="https://hsalghanimdelivery.com/company/connect-people-7"
                              title="Connect People"
                            >
                              Connect People
                            </a>
                          </div>
                          <div className="location">
                            <label
                              className="fulltime"
                              title="Full Time/Permanent"
                            >
                              Full Time/Permanent
                            </label>
                            - <span>ad-Dawhah</span>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="col-lg-2 col-md-4">
                        <div className="listbtn">
                          <Link to="/ios-developer-2">View Details</Link>
                        </div>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed pretium nunc non justo placerat pulvinar. Vestibulum
                      ac ullamcorper sapien, nec scelerisq...
                    </p>
                  </li>
                  {/* job end */}
                  <li className=" featured ">
                    <div className="row">
                      <div className="col-lg-10 col-md-8">
                        <div className="jobimg">
                          <img
                            src="https://hsalghanimdelivery.com/company_logos/sphere-1536859528-893.jpg"
                            alt="Sphere"
                            title="Sphere"
                          />
                        </div>
                        <div className="jobinfo">
                          <h3>
                            <a
                              href="https://hsalghanimdelivery.com/job/frontend-developer-22"
                              title="Frontend Developer"
                            >
                              Frontend Developer
                            </a>{" "}
                            <i
                              className="fas fa-bolt"
                              title="This Job is Featured"
                            />{" "}
                          </h3>
                          <div className="companyName">
                            <a
                              href="https://hsalghanimdelivery.com/company/sphere-11"
                              title="Sphere"
                            >
                              Sphere
                            </a>
                          </div>
                          <div className="location">
                            <label className="fulltime" title="Part Time">
                              Part Time
                            </label>
                            - <span>Clarksville</span>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="col-lg-2 col-md-4">
                        <div className="listbtn">
                          <a href="https://hsalghanimdelivery.com/job/frontend-developer-22">
                            View Details
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed pretium nunc non justo placerat pulvinar. Vestibulum
                      ac ullamcorper sapien, nec scelerisq...
                    </p>
                  </li>
                  {/* job end */}
                  <li className=" featured ">
                    <div className="row">
                      <div className="col-lg-10 col-md-8">
                        <div className="jobimg">
                          <img
                            src="https://hsalghanimdelivery.com/admin_assets/no-image.png"
                            alt="New Design Studio"
                            title="New Design Studio"
                          />
                        </div>
                        <div className="jobinfo">
                          <h3>
                            <a
                              href="https://hsalghanimdelivery.com/job/web-designer-21"
                              title="Web Designer"
                            >
                              Web Designer
                            </a>{" "}
                            <i
                              className="fas fa-bolt"
                              title="This Job is Featured"
                            />{" "}
                          </h3>
                          <div className="companyName">
                            <a
                              href="https://hsalghanimdelivery.com/company/new-design-studio-3"
                              title="New Design Studio"
                            >
                              New Design Studio
                            </a>
                          </div>
                          <div className="location">
                            <label className="fulltime" title="Internship">
                              Internship
                            </label>
                            - <span>Milton</span>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="col-lg-2 col-md-4">
                        <div className="listbtn">
                          <a href="https://hsalghanimdelivery.com/job/web-designer-21">
                            View Details
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed pretium nunc non justo placerat pulvinar. Vestibulum
                      ac ullamcorper sapien, nec scelerisq...
                    </p>
                  </li>
                  {/* job end */}
                  <li className="col-lg-12">
                    <div className="jobint text-center">
                      <img src="https://www.sharjeelanjum.com/demos/jobsportal-update/images/google-ad-wide.jpg" />
                    </div>
                  </li>
                  {/* job end */}
                  <li className=" featured ">
                    <div className="row">
                      <div className="col-lg-10 col-md-8">
                        <div className="jobimg">
                          <img
                            src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                            alt="Abaris Softech Pvt Ltd"
                            title="Abaris Softech Pvt Ltd"
                          />
                        </div>
                        <div className="jobinfo">
                          <h3>
                            <a
                              href="https://hsalghanimdelivery.com/job/ios-developer-2"
                              title="IOS Developer"
                            >
                              IOS Developer
                            </a>{" "}
                            <i
                              className="fas fa-bolt"
                              title="This Job is Featured"
                            />{" "}
                          </h3>
                          <div className="companyName">
                            <a
                              href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9"
                              title="Abaris Softech Pvt Ltd"
                            >
                              Abaris Softech Pvt Ltd
                            </a>
                          </div>
                          <div className="location">
                            <label
                              className="fulltime"
                              title="Full Time/Permanent"
                            >
                              Full Time/Permanent
                            </label>
                            - <span>Auburn</span>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="col-lg-2 col-md-4">
                        <div className="listbtn">
                          <a href="https://hsalghanimdelivery.com/job/ios-developer-2">
                            View Details
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Proin fermentum condimentum mauris, non posuere urna
                      consectetur quis. Suspendisse semper eu...
                    </p>
                  </li>
                  {/* job end */}
                  {/* job end */}
                </ul>
                {/* Pagination Start */}
                <div className="pagiWrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="showreslt">
                        Showing Jobs : 1 - 8 Total 8
                      </div>
                    </div>
                    <div className="col-lg-7 text-right"></div>
                  </div>
                </div>
                {/* Pagination end */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default RequiredSkills;

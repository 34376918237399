import logo from './logo.svg';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/all.min.css';
import '../src/assets/css/main.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/custom.css'
import { Navigate, Route, Routes } from 'react-router-dom';
import CompanyPage from './pages/company/Index';
import DashBoardPage from './pages/dashboard/Index';
import Header from './components/common/header/Header';
import Footer from './components/common/footer/Footer';
import CompanyProfilePage from './pages/companyProfile/Index';
import CompanyPostedJobsPage from './pages/companyPostedJobs/Index';
import ListApplideUsers from './components/companyPostedJob/listAppliedUsers/ListApplideUsers';
import ApplicationProfilePage from './pages/companyPostedJobs/applicantProfile/Index';
import PageTitle from './components/common/pageTitle/PageTitle';
import CvSearchPackagePage from './pages/cvSearchPackage/Index';
import UnlockedUsersPage from './pages/unlockedUsers/Index';
import CompanyMessagePage from './pages/companyMessage/Index';
import CompanyFollowersPage from './pages/companyFollowers/Index';
import PostJobsPage from './pages/postJobs/Index';
import CompanyPublicProfile from './components/companyPublicProfile/CompanyPublicProfile';
import ViewDetail from './components/companyPublicProfile/jobHeaderLeft/viewDetail/ViewDetail';
import ReportAbuse from './components/companyPublicProfile/jobHeaderLeft/viewDetail/viewDetailLeft/reportAbuse/ReportAbuse';
import EmailFriend from './components/companyPublicProfile/jobHeaderLeft/viewDetail/viewDetailLeft/emailFriend/EmailFriend';
import HtmlSkill from './components/companyPublicProfile/jobHeaderLeft/viewDetail/viewDetailLeft/htmlSkill/HtmlSkill';
import JavaScriptSkill from './components/companyPublicProfile/jobHeaderLeft/viewDetail/viewDetailLeft/javascriptSkill/JavascriptSkill';

function App() {
  return (
    <>
    <Header/>
    <PageTitle/>
      <Routes>
        <Route path='/' element={<Navigate to={"/company"} />} />
        <Route path='/company' element={<CompanyPage />}>
          <Route path='' element={<DashBoardPage />} />
          <Route path='company-profile' element={<CompanyProfilePage/>} />
          <Route path='posted-jobs' element={<CompanyPostedJobsPage/>} />
          <Route path='posted-jobs/list-applied-users' element={<ListApplideUsers/>} />
          <Route path='list-applied-users/applicant-profile' element={<ApplicationProfilePage/>} />
          <Route path='company-packages' element={<CvSearchPackagePage/>} />
          <Route path='unloced-seekers' element={<UnlockedUsersPage/>} />
          <Route path='company-messages' element={<CompanyMessagePage/>} />
          <Route path='company-followers' element={<CompanyFollowersPage/>} />
          <Route path='post-job' element={<PostJobsPage/>} />
          <Route path='company-details' element={<CompanyPublicProfile/>} />
        </Route>
        <Route path="/ios-developer-2" element={<ViewDetail />} />
        <Route path="/report-abuse" element={<ReportAbuse />} />
        <Route path="/email-to-friend" element={<EmailFriend />} />
        <Route path="/job_skill_css" element={<HtmlSkill />} />
        <Route path="/job_skill_html" element={<HtmlSkill />} />
        <Route path="/job_skill-js" element={<JavaScriptSkill />} />
      </Routes>
      <Footer/>
    </>
  );
}

export default App;

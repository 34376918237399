import JobHeader from "./jobHeader/JobHeader";
import JobHeaderLeft from "./jobHeaderLeft/JobHeaderLeft";
import JobHeaderRight from "./jobHeaderRight/JobHeaderRight";

function CompanyPublicProfile() {
  return (
    <>
      <div className="listpgWraper">
        <div className="container">
          <JobHeader />
          <div className="row">
            <JobHeaderLeft />

            <JobHeaderRight />
          </div>
        </div>
      </div>
    </>
  );
}
export default CompanyPublicProfile;

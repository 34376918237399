import { Link, NavLink } from "react-router-dom"
import { FaBlackTie } from "react-icons/fa"

function Aside() {
    return (
        <>
            <div className="usernavwrap" style={{ background: '#f3f6ff' }}>
                <ul className="usernavdash">
                    <li >
                        <NavLink to="/company">
                            <i className="fas fa-tachometer" aria-hidden="true" />
                            Dashboard</NavLink>
                    </li>
                    <li>
                        <NavLink to="company-profile">
                            <i className="fas fa-pencil" aria-hidden="true" />
                            Edit Profile
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="company-details">
                            <i className="fas fa-user-alt" aria-hidden="true" />
                            Company Public Profile
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="post-job">
                            <i className="fas fa-desktop" aria-hidden="true" /> Post Job</NavLink>
                    </li>
                    <li>
                        <NavLink to="posted-jobs">
                            <FaBlackTie />
                            Company Jobs</NavLink>
                    </li>
                    <li>
                        <NavLink to="company-packages">
                            <i className="fas fa-search" aria-hidden="true" />
                            CV Search Packages
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="unloced-seekers">
                            <i className="fas fa-user" aria-hidden="true" />
                            Unlocked Users
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="company-messages">
                            <i className="fas fa-envelope" aria-hidden="true" />
                            Company Messages
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="company-followers">
                            <i className="fas fa-users" aria-hidden="true" />
                            Company Followers</NavLink>
                    </li>
                    <li>
                        <a href="https://hsalghanimdelivery.com/company/logout"><i className="fas fa-sign-out" aria-hidden="true" /> Logout</a><form id="logout-form" action="https://hsalghanimdelivery.com/company/logout" method="POST" style={{ display: 'none' }}><input type="hidden" name="_token" defaultValue="phr5mPhUDx2HebVXkjRT8y8zhOlNVZhD93h3dXlE" /></form></li></ul>
            </div>
            <div className="row">
                <div className="col-md-12"><img src="https://www.sharjeelanjum.com/demos/jobsportal-update/images/large-ad.jpg" /></div>
            </div>


        </>
    )
}

export default Aside

function Language() {
    return (
        <>
            <div className="job-header">
                <div className="jobdetail">
                    <h3>Languages</h3>
                    <div id="language_div"><div className="col-mid-12"><table className="table table-bordered table-condensed"><tbody><tr id="language_10">
                        <td><span className="text text-success">English</span></td>
                        <td><span className="text text-success">Expert</span></td></tr><tr id="language_11">
                            <td><span className="text text-success">Turkish</span></td>
                            <td><span className="text text-success">Intermediate</span></td></tr><tr id="language_12">
                            <td><span className="text text-success">Arabic</span></td>
                            <td><span className="text text-success">Intermediate</span></td></tr><tr id="language_13">
                            <td><span className="text text-success">Chinese</span></td>
                            <td><span className="text text-success">Beginner</span></td></tr></tbody></table></div></div>
                </div>
            </div>
        </>
    )
}
export default Language
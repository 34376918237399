import { Link } from "react-router-dom"

function ListApplideUsers() {
    return (
        <>
            <div className="myads">
                <h3>Job Applications</h3>
                <ul className="searchList">
                    {/* job start */}
                    <li>
                        <div className="row">
                            <div className="col-md-5 col-sm-5">
                                <div className="jobimg"><img src="https://hsalghanimdelivery.com/admin_assets/no-image.png" style={{ maxHeight: 100 }} alt="Daniyal Khan" title="Daniyal Khan" /></div>
                                <div className="jobinfo">
                                    <h3><a href="https://hsalghanimdelivery.com/applicant-profile/11">Daniyal Khan</a></h3>
                                    <div className="location"> Saharanpur, Uttar Pradesh, India</div>
                                </div>
                                <div className="clearfix" />
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="minsalary">1500 USD <span>/ Monthly</span></div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="listbtn"><Link to="/company/list-applied-users/applicant-profile">View Profile</Link></div>
                            </div>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla at enim quis tincidunt. Nulla condimentum dapibus efficitur. In massa felis,...</p>
                    </li>
                    {/* job end */}
                </ul>
            </div>

        </>
    )
}
export default ListApplideUsers
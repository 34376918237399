import { Link } from "react-router-dom";

import facebook from "../../../assets/img/public-profile/facebook.png";
import instagram from "../../../assets/img/public-profile/instagram.png";
import google from "../../../assets/img/public-profile/google.png";
import twitter from "../../../assets/img/public-profile/twitter.png";
import cross from "../../../assets/img/public-profile/close.png";

function JobHeader() {
  return (
    <>
      <div className="job-header">
        <div className="jobinfo">
          <div className="row">
            <div className="col-md-8 col-sm-8">
              {/* Candidate Info */}
              <div className="candidateinfo">
                <div className="userPic">
                  <a href="https://hsalghanimdelivery.com/company/abaris-softech-pvt-ltd-9">
                    <img
                      src="https://hsalghanimdelivery.com/company_logos/abaris-softech-pvt-ltd-1670425038-356.png"
                      alt="Abaris Softech Pvt Ltd"
                      title="Abaris Softech Pvt Ltd"
                    />
                  </a>
                </div>
                <div className="title">Abaris Softech Pvt Ltd</div>
                <div className="desi">Information Technology</div>
                <div className="loctext">
                  <i className="fa fa-history" aria-hidden="true" />
                  Member Since, Jul 27, 2018
                </div>
                <div className="loctext">
                  <i className="fa fa-map-marker" aria-hidden="true" />
                  Namberdar Estate, 2nd Floor, B 98, Kailash Hills, New Friends
                  Colony, New Delhi, Delhi 110025, INDIA
                </div>
                <div className="clearfix" />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              {/* Candidate Contact */}
              <div className="candidateinfo">
                <div className="loctext">
                  <i className="fas fa-phone" aria-hidden="true" />{" "}
                  <a href="tel:+918851746286">+918851746286</a>
                </div>
                <div className="loctext">
                  <i className="fas fa-envelope" aria-hidden="true" />{" "}
                  <a href="mailto:hr@abaris.in">hr@abaris.in</a>
                </div>
                <div className="loctext">
                  <i className="fas fa-globe" aria-hidden="true" />{" "}
                  <a href="https://www.abarissoftech.com" target="_blank">
                    https://www.abarissoftech.com
                  </a>
                </div>
                <div className="cadsocial social_network">
                  {" "}
                  <a
                    href="https://www.facebook.com/Abaris-Softech-107870812076527"
                    target="_blank"
                  >
                    <img
                      src={facebook}
                      className="facebook square iconss"
                      aria-hidden="true"
                    />
                  </a>
                  <a href="https://twitter.com/" target="_blank">
                    <img
                      src={twitter}
                      className="twitter square iconss"
                      aria-hidden="true"
                    />
                  </a>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i className="fa fa-linkedin-square" aria-hidden="true" />
                  </a>
                  <a href="https://plus.google.com/" target="_blank">
                    <img
                      src={google}
                      className="google square iconss"
                      aria-hidden="true"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/abaris_sof_tech/"
                    target="_blank"
                  >
                    <img
                      src={instagram}
                      className="fa fa-pinterest-square iconss"
                      aria-hidden="true"
                    />
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Buttons */}
        <div className="jobButtons">
          {" "}
          <a
            href="https://hsalghanimdelivery.com/add-to-favourite-company/abaris-softech-pvt-ltd-9"
            className="btn"
          >
            <i className="fa fa-floppy-o" aria-hidden="true" /> Add to Favourite
          </a>{" "}
          <Link
            to="/report-abuse"
            className="btn report"
          >
            <i className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
            Report Abuse
          </Link>{" "}
          <Link
            to="#"
            className="btn"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <i className="fa fa-envelope" /> Send Message
          </Link>{" "}
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="round">
                <img src={cross} className="cros" />
              </div>
            </div>
            <div className="modal-body">
              <div className="bodymodal">
                <div className="swal-title loged">You are not Loged in</div>
                <div className="swal-content">
                  <div className="paraText">
                    Please
                    <a
                      className="btn"
                      href="https://hsalghanimdelivery.com/login"
                    >
                      log in
                    </a>
                    as a Canidate and try again.
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btnok"
                data-bs-dismiss="modal"
              >
                ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default JobHeader;

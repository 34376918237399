

function CandidateContact() {
    return (
        <>
            <div className="job-header">
                <div className="jobdetail">
                    <h3>Candidate Contact</h3>
                    <div className="candidateinfo">
                        <div className="loctext"><i className="fa fa-phone" aria-hidden="true" /> <a href="tel:+918851454545">+918851454545</a></div>
                        <div className="loctext"><i className="fa fa-mobile" aria-hidden="true" /> <a href="tel:+918989898989">+918989898989</a></div>
                        <div className="loctext"><i className="fa fa-envelope" aria-hidden="true" /> <a href="mailto:seeker@hsalghanimdelivery.com">seeker@hsalghanimdelivery.com</a></div>
                        <div className="loctext"><i className="fa fa-map-marker" aria-hidden="true" /> Dummy Street Address 123 Delhi</div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CandidateContact